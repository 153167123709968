import { tongke } from '@/axios'
class PeopleJury {
  /**
   * @functionName quitList
   * @param { Object } params.page
   * @param { Object } params.size
   * @param { Object } params.from  来源，选任-xrjh|增补-zbjh
   *  非必填
   * @param { Object } params.bmfs  报名方式，1-随机抽选|2-个人申请|3-组织推荐
   * @param { Object } params.keyword  关键词
   * @Description 退出人员列表
   * @return { Object }
   */
  quitList(params) {
    return tongke.get('/quxian/tclb', { params })
  }

  /**
   * @functionName quitList
   * @param { Object } params.page
   * @param { Object } params.size
   * @param { Object } params.from  来源，选任-xrjh|增补-zbjh
   *  非必填
   * @param { Object } params.bmfs  报名方式，1-随机抽选|2-个人申请|3-组织推荐
   * @param { Object } params.keyword  关键词
   * @Description 人民陪审员列表
   * @return { Object }
   */
  peopleList(params) {
    return tongke.get('/quxian/rmpsy', { params })
  }
  // 人民陪审员详情
  peopleDetail(sfzhm) {
    return tongke.get(`/quxian/rmpsy/${sfzhm}`)
  }
  // 人民陪审员信息修改
  peopleUpdata(params) {
    return tongke.put(`/quxian/rmpsy/${params.sfzhm}`, params)
  }
  /**
   * @functionName statusUpdate
   * @param { Number }  params.rzzt 任职状态
   * @param { String }  params.bgztsm 变更状态说明
   * @Description 任职状态变更
   * @return { Object }
   */
  statusUpdate(params) {
    return tongke.post(`/quxian/rmpsstbg/${params.sfzhm}`, params)
  }
}
export default new PeopleJury()
